import { FooterServiceType } from "./footer.service.type";
import { AppType, APP_TYPE } from "../app.type";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Language } from "../../../../shared/settings/settings";

@Injectable()
export class FooterService implements FooterServiceType {
  onLangChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  async changeLanguage(language: Language) {
    this.app.language = language;
    await this.app.users.setLanguage(language);
    this.onLangChange.next(true);
    this.app.attachments.setTooltip();
  }
}
