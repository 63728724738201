import { ComponentSettings } from "../settings/settings";

const settings = {
  permission: {},
  list: {},
  url: {},
  text: {
    version: {
      de: "Version: ",
      en: "Version: ",
      nl: "Versie: ",
    },
    bugs: {
      de: "Weitere kleinere Fehler wurden behoben, um die allgemeine Stabilität und Leistung zu verbessern.",
      en: "Other minor bugs have been fixed to improve the overall stability and performance.",
      nl: "Andere kleine bugs zijn opgelost om de algehele stabiliteit en prestaties te verbeteren.",
    },
    support: {
      de: "Benötigen Sie Unterstützung?",
      en: "Need Support?",
      nl: "Hulp Nodig?",
    },
    contact: {
      de: "Bei Fragen oder Problemen wenden Sie sich bitte an unser Team unter <b><i>support@am-sys.com</i></b> oder <b><i>+49 (0) 4103 92 853 60</i></b>",
      en: "If you have questions or need assistance, please contact our team at <b><i>support@am-sys.com</i></b> or <b><i>+49 (0) 4103 92 853 60</i></b>",
      nl: "Als u vragen heeft of hulp nodig heeft, neem contact op met ons team op <b><i>support@am-sys.com</i></b> of <b><i>+49 (0) 4103 92 853 60</i></b>",
    },
  },
  field: {},
  additionalInfo: {},
};
export const releaseNotesModal: ComponentSettings<typeof settings> = settings;
