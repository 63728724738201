import { AppType, APP_TYPE } from "../app.type";
import { Injectable, Inject } from "@angular/core";
import { UserSettingsServiceType } from "./user-settings.service.type";
import { getUserSettings, updateUserSettings } from "../api.service";

@Injectable()
export class UserSettingsService implements UserSettingsServiceType {
  userSettings: any = {};
  currentUserEmailPreferences: number[] = [];
  cleanUserEmailPreferences: number[] = [];
  constructor(@Inject(APP_TYPE) private app: AppType) {}

  async getCurrentUserSettings(userName: string) {
    this.userSettings = await getUserSettings(userName);

    if (this.userSettings != null) {
      this.currentUserEmailPreferences = [
        ...this.userSettings.preferences.emails,
      ];
      this.cleanUserEmailPreferences = [...this.currentUserEmailPreferences];
    }
  }

  addSelectedOptions(email: string) {
    const emailNumber = Number(email);
    if (this.currentUserEmailPreferences.includes(emailNumber)) {
      const index = this.currentUserEmailPreferences.indexOf(emailNumber);
      this.currentUserEmailPreferences.splice(index, 1);
    } else {
      this.currentUserEmailPreferences.push(emailNumber);
    }
  }

  async updateSettings() {
    this.userSettings.preferences.emails = this.currentUserEmailPreferences;
    await updateUserSettings(this.userSettings);
    this.app.state.next({
      hasSuccess: true,
      successText: this.app.text.userSettings.emailPreferencesSet,
    });
    setTimeout(() => {
      this.app.state.next({ hasSuccess: false });
    }, 3000);
    // get updated user settings
    await this.getCurrentUserSettings(this.userSettings.id);
  }

  async setMFAPreferences(userName?: string) {
    if (this.app.users.isNew && userName != null) {
      // get the current user settings to update them if mfaPreferences was set to true during user creation
      await this.getCurrentUserSettings(userName);
      this.userSettings.mfaPreferences.email = this.app.users.mfaPreferences;
    } else {
      this.userSettings.mfaPreferences.email =
        !this.userSettings.mfaPreferences.email;
    }

    await updateUserSettings(this.userSettings);
    this.app.state.next({
      hasSuccess: true,
      successText: this.app.text.userSettings.securityPreferencesSet,
    });

    setTimeout(() => {
      this.app.state.next({ hasSuccess: false });
    }, 3000);

    // get updated user settings
    await this.getCurrentUserSettings(this.userSettings.id);
  }
}
