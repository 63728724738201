<app-modal
  #discardChangesModal
  [modalMessage]="app.text.user.discard"
  [modalTitle]="app.text.user.modalTitle"
  (ok)="discardChanges()"
>
</app-modal>
<app-modal
  #deleteUserModal
  [modalMessage]="app.text.user.deleteMessage"
  [modalTitle]="app.text.user.modalTitle"
  (ok)="app.users.delete()"
>
</app-modal>
<div class="col-md-12 no-padding">
  <div class="control-bar d-flex">
    <button
      class="btn btn-control me-auto p-2"
      (click)="app.routing.navigateUsers()"
    >
      <i class="far fa-arrow-left"></i> &nbsp; {{ app.text.app.backToList }}
    </button>

    <span *ngIf="app.unlockedId === null">
      <span *ngIf="!app.users.isNew">
        <app-next
          class="pull-left"
          [current]="app.users.currentUser.name"
          [array]="ids"
          (navigate)="app.routing.navigateUser($event)"
        ></app-next>
      </span>
    </span>
  </div>
</div>

<div *ngIf="app.users.currentUser !== null">
  <div *ngFor="let box of app.list.user.boxes">
    <div class="box-user col-md-7 mx-5 my-3 p-3" *ngIf="box != null">
      <app-field
        *ngFor="let field of getListOfFields(box); index as index"
        [model]="app.users.currentUserModel"
        [field]="field"
        [index]="index"
        [disabled]="app.isLocked(app.users.currentId)"
      ></app-field>

      <div class="controls">
        <div class="m-1 mb-2 d-flex justify-content-start">
          <app-unlock
            [id]="app.users.currentId"
            [permission]="app.users.hasEditPermission() && !app.users.isNew"
          >
            <span
              *ngIf="
                app.users.modelHasChanges();
                then thenBlock;
                else elseBlock
              "
            >
            </span>

            <ng-template #thenBlock>
              <button
                *ngIf="!app.users.isNew"
                class="btn btn-sm btn-clean"
                (click)="discardChangesModal.open()"
              >
                <i class="fas fa-times"></i>&nbsp;

                {{ app.text.user.discardBtn }}
              </button>
            </ng-template>
            <ng-template #elseBlock>
              <button class="btn btn-sm btn-clean" (click)="discardChanges()">
                <i class="fas fa-times"></i>&nbsp;
                {{ app.text.user.closeBtn }}
              </button>
            </ng-template>
          </app-unlock>
          <button
            *ngIf="app.users.hasEditPermission()"
            class="btn btn-save btn-sm"
            (click)="app.users.save()"
            [ngClass]="{ 'mt-4': app.users.isNew }"
            [disabled]="app.users.isDisabled()"
          >
            <i class="fa fa-check"> </i>&nbsp;
            {{ app.users.isNew ? app.text.user.create : app.text.user.edit }}
          </button>
          <span *ngIf="app.unlockedId === null">
            <span *ngIf="!app.users.isNew">
              <button
                *ngIf="
                  app.model[this.app.fieldId.user.active] &&
                  app.users.hasEditPermission()
                "
                class="btn"
                (click)="app.users.deactivateUser(); app.unlockedId = null"
                [ngbTooltip]="app.text.user.deactivateUser"
              >
                <i class="fa fa-user-times"></i>
              </button>
              <button
                *ngIf="app.users.hasEditPermission()"
                class="btn btn-sm"
                (click)="deleteUserModal.open()"
                [ngbTooltip]="app.text.user.delete"
              >
                <i class="fa fa-trash"></i>
              </button>
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>

  <!-- //Settings -->
  <div class="col-md-7 mx-5 my-3 no-padding" *ngIf="showEmailsBox">
    <app-user-settings></app-user-settings>
  </div>

  <!-- Security -->
  <div
    *ngIf="
      !app.auth.isTeam &&
      (app.customers.expectCurrent === 'sew' ||
        app.customers.expectCurrent === 'mtu')
    "
    class="col-md-7 mx-5 my-3 no-padding card mb-3"
  >
    <div class="card-header p-2">{{ app.text.userSettings.security }}</div>
    <div class="m-2 d-flex align-items-center">
      <button
        class="btn btn-sm d-flex align-items-center"
        (click)="updateMFAPreferences()"
      >
        <i *ngIf="!isMFAPreferenceEnabled()" class="far fa-square"></i>
        <i *ngIf="isMFAPreferenceEnabled()" class="far fa-check-square"></i>
        <label class="fieldName">{{ app.text.userSettings.MFAEmail }}</label>
      </button>
    </div>
  </div>
</div>
