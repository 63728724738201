import { ComponentSettings, FieldTypes } from "../settings/settings";
import { GraphName } from "../types/graphName";

export const abbreviatedMonths: any = {
  january: { text: { de: "Jan", en: "Jan" } },
  february: { text: { de: "Feb", en: "Feb" } },
  march: { text: { de: "März", en: "Mar" } },
  april: { text: { de: "Apr", en: "Apr" } },
  may: { text: { de: "Mai", en: "May" } },
  june: { text: { de: "Jun", en: "Jun" } },
  july: { text: { de: "Jul", en: "Jul" } },
  august: { text: { de: "Aug", en: "Aug" } },
  september: { text: { de: "Sep", en: "Sep" } },
  october: { text: { de: "Okt", en: "Oct" } },
  november: { text: { de: "Nov", en: "Nov" } },
  december: { text: { de: "Dec", en: "Dec" } },
};

const settings = {
  permission: {
    view: false,
    viewDashboard: false,
  },
  text: {
    dashboard: {
      de: "Dashboard",
      en: "Dashboard",
      nl: "Dashboard",
    },
    statusByMPNTitle: {
      de: "Herstellerteilenummer Übersicht",
      en: "Manufacturer Part Number Overview",
      nl: "Fabrikant Artikel Nummer Overzicht",
    },
    statusByCPNTitle: {
      de: "Kundenteilenummer Übersicht",
      en: "Customer Part Number Overview",
      nl: "Artikel Nummer Overzicht",
    },
    statusByOMFTitle: {
      de: "Fälle nach Status",
      en: "Cases according to status",
      nl: "Issues per status",
    },
    statusByMonthTitle: {
      de: "Neue/Abgeschlossene Fälle (letzte 12 Monate)",
      en: "New/Closed Cases (last 12 months)",
      nl: "Nieuwe/afgesloten Issues (laatste 12 maanden)",
    },
    statusByMonthSubtitle: {
      de: "Fälle insgesamt: ",
      en: "Total Cases: ",
      nl: "Totaal zaken: ",
    },
    licenseWarningText: {
      de: "Diese Grafik ist nicht in Ihrem Lizenzmodell enthalten. Kontaktieren Sie Ihren Projektmanager für einen Testzugang und weiteren Informationen.",
      en: "This graphic is not included in your license model. Contact your project manager for trial access and further information.",
      nl: "Deze afbeelding is niet inbegrepen in je licentiemodel. Neem contact op met je projectmanager voor proeftoegang en meer informatie.",
    },
    january: {
      de: "Januar",
      en: "January",
    },
    february: {
      de: "Februar",
      en: "February",
    },
    march: {
      de: "März",
      en: "March",
    },
    april: {
      de: "April",
      en: "April",
    },
    may: {
      de: "Mai",
      en: "May",
    },
    june: {
      de: "Juni",
      en: "June",
    },
    july: {
      de: "Juli",
      en: "July",
    },
    august: {
      de: "August",
      en: "August",
    },
    september: {
      de: "September",
      en: "September",
    },
    october: {
      de: "Oktober",
      en: "October",
    },
    november: {
      de: "November",
      en: "November",
    },
    december: {
      de: "Dezember",
      en: "December",
    },
    title: { de: "Statistik", en: "Charts", nl: "Grafieken" },
    unknown: {
      de: "Unbekannt",
      en: "Unknown",
    },
    number: {
      de: "Anzahl",
      en: "Number",
    },
    vehicle: {
      de: "Fälle pro Fahrzeug",
      en: "Cases per Vehicle",
      nl: "Issues per Materieelserie",
    },
    dinCode: {
      de: "Auswirkung nach DIN-Code",
      en: "Impact for each DIN-code",
      nl: "Impact per DIN-code",
    },
    fleet: {
      de: "Auswirkung nach Flotte",
      en: "Impact per fleet",
      nl: "Impact per vloot",
    },
    status: {
      de: "Fälle nach Status",
      en: "Cases according to status",
      nl: "Issues per status",
    },
    department: {
      de: "Fälle pro Abteilung",
      en: "Cases per Department",
      nl: "Issues per afdeling",
    },
    responsible: {
      de: "Fälle pro Verantwortlicher",
      en: "Cases per Responsible",
      nl: "Issues per OM-aanspreekpunt",
    },
    totalCostLTB: {
      de: "LNB Gesamtkosten",
      en: "LNB Total Costs",
    },
    omapproachesCurrentYear: {
      de: "OM-Ansätze (Aktuelles Jahr)",
      en: "OM-Approaches (Current Year)",
      nl: "OM-Benaderingen (Huidig Jaar)",
    },
    omapproachesPerYear: {
      de: "OM-Ansätze (Jährlich)",
      en: "OM-Approaches (Per Year)",
      nl: "OM-Benaderingen",
    },
    solutionsCurrentYear: {
      de: "Ausgewählte Lösungen (Aktuelles Jahr)",
      en: "Selected Solutions (Current Year)",
      nl: "Geselecteerde Afhandelstrategieën (Huidig Jaar)",
    },
    solutionsPerYear: {
      de: "Ausgewählte Lösungen (Gesamt)",
      en: "Selected Solutions (Overall)",
      nl: "Geselecteerde Afhandelstrategieën (Totaal)",
    },
    sortBy_alphabetic: {
      de: "alphabetisch",
      en: "alphabetic",
      nl: "alfabetisch",
    },
    sortBy_impacts: {
      de: "Auswirkungen",
      en: "impacts",
      nl: "Impact",
    },
    sortBy: {
      de: "sortieren nach: ",
      en: "sort by: ",
      nl: "gesorteerd naar: ",
    },
    closed: {
      de: "Geschlossen",
      en: "Closed",
      nl: "Gesloten",
    },
    open: {
      de: "Offen",
      en: "Open",
      nl: "Open",
    },
    others: {
      de: "Sonstige",
      en: "Others",
      nl: "Overige",
    },
    quantity: {
      de: "Anzahl",
      en: "Number",
      nl: "Nummer",
    },
  },
  list: {
    graphs: [
      GraphName.Vehicles,
      GraphName.DinCodes,
      GraphName.Fleet,
      GraphName.Status,
      GraphName.Departments,
      GraphName.Responsibles,
      GraphName.OMApproachesCurrentYear,
      GraphName.OMApproachesPerYear,
    ],
    vehicles: [""],
  },
  url: {},
  field: {
    abbreviatedMonths: {
      type: FieldTypes.options,
      text: {
        de: "Abbreviated Months",
        en: "Abbreviated Months",
        nl: "Abbreviated Months",
      },
      options: abbreviatedMonths,
    },
  },
  additionalInfo: {},
};

export const graphs: ComponentSettings<typeof settings> = settings;
