import { Component } from "@angular/core";
import { getApp } from "../app";
import { environment } from "../../environments/environment";
import { Router } from "@angular/router";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  app = getApp((app) => {
    this.app = app;
  });
  year = 0;
  version = "";

  constructor(private router: Router) {}

  ngOnInit() {
    const date = new Date();
    this.year = date.getFullYear();
    this.version = environment.version;
  }

  isDisabled() {
    if (
      this.app.customers.expectCurrent === "oc" ||
      this.app.customers.expectCurrent === "omp" ||
      this.app.view === "pcn" ||
      this.router.url.split("/").includes("login")
    ) {
      return true;
    }
    return false;
  }
}
