import { Component, OnInit } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";
import { getApp } from "../app";
import { getDashboardData } from "../api.service";
import { NON_OM_CLIENT, RM_CLIENT } from "../customers/customers.service";
import { Subscription } from "rxjs";
import { DashboardModel } from "../../../../shared/models/dashboard";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  rmClient: boolean = false;
  nonOMClient: boolean = false;
  htmlContent: SafeHtml = "";
  private langChangeSubscription: Subscription = new Subscription();

  app = getApp((app) => {
    this.app = app;
  });

  constructor() {}

  async ngOnInit() {
    this.rmClient = this.app.customers.getRMCustomers() === RM_CLIENT;
    this.nonOMClient = this.app.customers.getOMCustomers() === NON_OM_CLIENT;

    await this.loadDashboardsPage();

    this.langChangeSubscription = this.app.footer.onLangChange.subscribe(
      async (value) => {
        if (value) {
          await this.loadDashboardsPage();
        }
      }
    );
  }

  async loadDashboardsPage() {
    await this.getDashboardData();

    setTimeout(async () => {
      await this.loadSingleLineScripts();
      this.loadTextScripts();
    }, 10);
  }

  async getDashboardData() {
    try {
      const dashboardModel: DashboardModel = {
        mpnStatusChart: {
          data: this.app.field.getOptionsAndTextsForASpecificField(
            this.app.fieldId.manufacturer.obsolescenceStatus
          ),
          isDisabled: !this.rmClient,
          isVisible: true,
        },
        cpnStatusChart: {
          data: this.app.field.getOptionsAndTextsForASpecificField(
            this.app.fieldId.part.obsolescenceStatus
          ),
          isDisabled: !this.rmClient,
          isVisible: true,
        },
        omfStatusChart: {
          data: this.app.field.getOptionsAndTextsForASpecificField(
            this.app.fieldId.thread.omfStatus
          ),
          isDisabled: false,
          isVisible: !(
            this.nonOMClient ||
            this.app.state.isAnalyst ||
            this.app.state.isRMAdmin
          ),
        },
        openAndClosedChart: {
          data: this.app.field.getOptionsAndTextsForASpecificField(
            this.app.fieldId.graphs.abbreviatedMonths
          ),
          isDisabled: false,
          isVisible: !(
            this.nonOMClient ||
            this.app.state.isAnalyst ||
            this.app.state.isRMAdmin
          ),
        },
      };

      let response = await getDashboardData(
        this.app.customers.expectCurrent,
        dashboardModel
      );

      this.htmlContent = response
        .replace("{{statusByMPNTitle}}", this.app.text.graphs.statusByMPNTitle)
        .replace("{{statusByCPNTitle}}", this.app.text.graphs.statusByCPNTitle)
        .replace("{{statusByOMFTitle}}", this.app.text.graphs.statusByOMFTitle)
        .replace(
          "{{statusByMonthTitle}}",
          this.app.text.graphs.statusByMonthTitle
        )
        .replace(
          "{{statusByMonthSubtitle}}",
          this.app.text.graphs.statusByMonthSubtitle
        )
        .replace(
          /{{licenseWarningText}}/g,
          this.app.text.graphs.licenseWarningText
        );
    } catch (error) {
      console.error("Error loading Current Obsolescence Status by MPN:", error);
    }
  }

  async loadSingleLineScripts() {
    const scripts = document.querySelectorAll("script");
    const scriptLoadPromises: Promise<void>[] = [];

    scripts.forEach((oldScript) => {
      if (oldScript.src) {
        if (document.querySelector(`script[src="${oldScript.src}"]`)) {
          const newScript = document.createElement("script");
          newScript.src = oldScript.src;
          newScript.async = false;

          const scriptPromise = new Promise<void>((resolve) => {
            newScript.onload = () => {
              resolve();
            };
          });

          scriptLoadPromises.push(scriptPromise);
          document.body.appendChild(newScript);
        }
      }
    });

    // Wait for all external scripts to load
    await Promise.all(scriptLoadPromises);
  }

  loadTextScripts() {
    const container = document.querySelector(".dashboard-container");

    if (container) {
      // Remove previously injected scripts & clear previous content
      document.querySelectorAll("script").forEach((script) => script.remove());
      container.innerHTML = "";
      container.innerHTML = this.htmlContent as string;

      const scripts = container.querySelectorAll("script");

      scripts.forEach((oldScript) => {
        if (!oldScript.src && oldScript.textContent) {
          try {
            const func = new Function(oldScript.textContent);
            func();
          } catch (e) {
            console.error("Error executing script:", e);
          }
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }
}
